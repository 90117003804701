.archive_apartment{
	margin-bottom: 110px;
	@media (max-width: 1024px) {
		margin-bottom: 80px;
	}

	.archive_content{

		display: grid;
		grid-gap: 30px;

		&.four_column{
		  	grid-template-columns: 1fr 1fr 1fr 1fr;	
		  	@media( max-width: 1200px ){
				grid-template-columns: 1fr 1fr;
			}
			@media( max-width: 767px ){
				grid-template-columns: auto;	
			}
		}

		&.three_column{
		  	grid-template-columns: 1fr 1fr 1fr;	
		  	@media( max-width: 1200px ){
				grid-template-columns: 1fr 1fr;
			}
			@media( max-width: 767px ){
				grid-template-columns: auto;	
			}
		}

		&.two_column{
		 	grid-template-columns: 1fr 1fr;	
		  	@media( max-width: 767px ){
				grid-template-columns: auto;	
			}
		}      

	}
	
}