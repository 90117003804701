.ova_apartment_single{
	width: 100%;

    .apartment-info {
    	display: grid;
    	grid-template-columns: 75% 25%;
    	margin-bottom: 50px; 

    	&.horizontal {
    		grid-template-columns: 100%;
    		.apartment-post-media {
    			margin: 0 0 30px 0;
    		}
    		.info-list {
    			display: flex;
    			justify-content: space-between;
    			.item {
    				padding: 20px;
                    border-bottom: none;
    			}
    		}
    	}

    	@media (max-width: 1024px) {
    		grid-template-columns: 100%; 
    	}

    	.apartment-post-media {
    		position: relative;
    		margin: 0 30px 0 0;
    		@media (max-width: 1024px) {
				margin: 0 0 30px 0;
			}
    		img {
    			display: block;
    			width: 100%;
    			height: 575px;
    			object-fit: cover;
    			@media (max-width: 767px) {
					height: 365px;
				}
    		}
    		.slide_gallery {
    			margin-bottom: 0;
    		}
    		.icon-apartment-embed {
    			transition: all .3s ease;
    			position: absolute;
    			top: 45px;
    			right: 30px;
    			z-index: 1;

    			display: flex;
    			justify-content: center;
    			align-items: center;
    			background-color: #fff;
    			padding: 12px 15px 12px 20px;
    			box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
    			-webkit-clip-path: polygon(25% 0%, 100% 1%, 100% 100%, 25% 100%, 0% 50%);
				clip-path: polygon(25% 0%, 100% 1%, 100% 100%, 25% 100%, 0% 50%);
    			cursor: pointer;
    			i {
    				transition: all .3s ease;
    				display: inline-flex;
    				font-size: 12px;
    				color: var(--primary);
    			}
    			&:hover {
    				transition: all .3s ease;
    				background-color: var(--primary);
    				i {
    					transition: all .3s ease;
    					color: #fff;
    				}
    			}
    		}
    	}

    	.info-list {
    		margin: 0;
    		padding: 20px 40px;
    		list-style-type: none;
    		background-color: var(--secondary);
    		max-height: 575px;
    		overflow: auto;
    		@media (max-width: 767px) {
    			padding: 20px 30px;
    		}

    		/* scrollbar */
			&::-webkit-scrollbar {
				width: 8px;
				height: 8px;
		        background: #e5e5e5;
			}
			&::-webkit-scrollbar-thumb {
				width: 8px;
				height: 8px;
				background: var(--primary);
				border-radius: 8px;
			}

    		.item {
    			min-width: 140px;
    			padding: 24px 0;
    			line-height: 1.4;
    			border-bottom: 1px solid rgb(228, 228, 228);
    			&:last-child {
    				border-bottom: none;
    			}
	    		.value {
	    			display: flex; 
	    			align-items: center;
	    			font-size: 24px;
	    			font-weight: 800;
	    			color: var(--primary); 
	    			margin-bottom: 2px;
	    			sup {
	    				display: inline-block;
	    				font-size: 16px;
	    				margin-left: 1px;
	    			}
	    		}
    		}
    	}
    }

	.apartment-title {
		margin: 0 0 26px 0;
		font-size: 36px;
		font-weight: 800;
		@media (max-width: 1024px) {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
    
    .main-content {
    	margin: 0 auto;
    	p {
    		margin: 0 0 25px 0;
    	}
    	.ova-post-text-list{
    		margin: 0;
    		padding: 20px 5px;
    		list-style-type: none;
    		li{
    			font-size: 18px;
    			font-weight: 600;
    			line-height: 1.4;
    			color: var(--heading);
                margin-bottom: 12px;
                &:last-child {
                	margin-bottom: 0;
                }
    		   &:before {
				    content: "\f105";
				    font-family: ovaicon !important;
				    font-style: normal;
				    font-weight: normal !important;
				    font-variant: normal;
				    text-transform: none;
				    line-height: 1;
				    display: inline-block;
				    position: relative;
				    color: var(--primary);
				    margin-right: 12px;
				    top: 3px;
				}
    		}
    	}
    }
	
}