.ova-apartment{
	display: grid;
	grid-gap: 30px;

	&.two_column{
		grid-template-columns: 1fr 1fr;
		@media( max-width: 767px ){
			grid-template-columns: auto;	
		}
	}
	&.three_column{
		grid-template-columns: 1fr 1fr 1fr;
		@media( max-width: 1200px ){
			grid-template-columns: 1fr 1fr;
		}
		@media( max-width: 767px ){
			grid-template-columns: auto;	
		}
	}
	&.four_column{
		grid-template-columns: 1fr 1fr 1fr 1fr;
		@media( max-width: 1200px ){
			grid-template-columns: 1fr 1fr;
		}
		@media( max-width: 767px ){
			grid-template-columns: auto;	
		}
	}
}