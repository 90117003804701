.ova-apartment-slider {
	.slide-apartment {
		
		.owl-stage-outer {
			padding: 40px 0;
		}

		.owl-dots{
			text-align: center;
			.owl-dot{
				outline: none;
				span{
					width: 16px;
					height: 4px;
					margin: 5px;
					background: #b3b0ba;
					display: block;
					-webkit-backface-visibility: visible;
					transition: opacity .2s ease;
				}
				&.active{
					span{
						background: var(--primary);
					}
				}
			}
		}

		.owl-nav{
			margin: 30px 0;
			text-align: center;
			button{
				transition: all .3s ease;
				display: inline-flex;
			    justify-content: center;
			    align-items: center;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				margin: 0 4px;
			    i{
			    	transition: all .3s ease; 
			    	display: inline-flex;
			    	color: var(--heading);
			    }

			    &.owl-next, &.owl-prev {
                    background-color: var(--secondary);
			    }

				&:hover{
					transition: all .3s ease; 
					background-color: var(--primary); 
					i{
						transition: all .3s ease; 
						color: #fff;
					}
				}
			}
		}
	}
}