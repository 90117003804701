.ova-apartment-item {

	&.item-template1 {
		height: 490px;
		perspective: 990px;
		@media (max-width: 767px) {
			height: 435px;
		}

		&:hover {
			.ova-media {
				transform: rotateY(180deg);
			}
		}

		.ova-media {
			position: relative;
			width: 100%;
			height: 100%;
			transition: all 0.8s cubic-bezier(0.8, -0.5, 0.5, 0.8);
			transform-style: preserve-3d;

			.apartment-img {
				img {
					display: block;
					width: 100%;
					height: 415px;
					object-fit: cover; 
					@media (max-width: 767px) {
						height: 360px;
					}
				}
			}

			.content-front, .content-back {
			  	position: absolute;
			  	width: 100%;
			  	height: 100%;
			  	-webkit-backface-visibility: hidden;
			  	backface-visibility: hidden;
			}

			.info {
				position: relative;
				margin: -30px auto 0;
				padding: 25px;
				background: #fff;
				box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
				width: calc(100% - 40px);
				.title {
		            margin: 0; 
		            font-size: 20px;
		            font-weight: 800; 
		            line-height: 1.4;   
				}
				.info-list-item {
					font-size: 14px;
					font-weight: 500;
					text-transform: lowercase;
				}
				.info-list-item-fixed {
					position: absolute;
					bottom: 100%;
					right: 0;
					padding: 11px 15px;
					background-color: var(--primary);
					color: #fff;
					font-size: 11px;
					line-height: 1;
					font-weight: 600;
					text-transform: uppercase;
				}
			}

			.content-back {
			    position: absolute;
			    padding: 50px;
			    background: var(--heading);
			    transform: rotateY(180deg);
			   
			    .title {
			    	transition: all .3s ease;   
		            margin: 0 20px 30px 0; 
		            font-size: 30px;
		            font-weight: 800; 
		            line-height: 1.26;   
		            color: #fff;
		            @media (max-width: 767px) {
						margin: 0 0 26px 0;
					}
		            &:hover {
		            	transition: all .3s ease;
		            	color: var(--primary);
		            }
				}

				.item {
					margin-bottom: 15px;
	    			&:last-child {
	    				margin-bottom: 0;
	    			}
		    		.value {
		    			display: flex; 
		    			align-items: center;
		    			font-size: 24px;
		    			font-weight: 800;
		    			color: var(--primary); 
		    			margin-bottom: 2px;
		    			sup {
		    				display: inline-block;
		    				font-size: 16px;
		    				margin-left: 1px;
		    			}
		    		}
		    		.label {
		    			color: #838b83;
		    		}
	    		}
			}

			.icon {
				transition: all .3s ease;
				position: absolute;
				bottom: 30px;
				right: 30px;
	    		display: inline-flex;
	    		justify-content: center;
	    		align-items: center;
	    		background-color: #fff;
	    		width: 50px;
	    		height: 50px;
	    		border-radius: 50%;
	        	i {
	        		transition: all .3s ease;
	        		display: inline-flex;
	        		color: var(--heading);
	        	}
	        	&:hover {
	        		transition: all .3s ease;
	        		background-color: var(--primary);
	        		i {
	        			transition: all .3s ease;
	        			color: #fff;
	        		}
	        	}
	    	}
		}
	}
}