.ova-apartment-item {
    &.item-template2 {

		&:hover {
			.ova-media {
	         	.mask {
	         		transition: transform .7s ease;
			   	 	-moz-transition: transform .7s ease;
			    	-webkit-transition: transform .7s ease;
	         		transform: scale(1,1);
	         	}
	         	.title {
	         		-webkit-transition: all .5s ease;
    				transition: all .5s ease;
			    	left: 60px;
			    	opacity: 1;
	         	}
	         	.icon {
	         		-webkit-transition: all .3s ease;
    				transition: all .3s ease;
			    	transition-delay: 0.3s; 
			    	top: 30px;
	         		opacity: 1; 
	         	}
			}
		}

	    .ova-media {
	    	position: relative;
	    	overflow: hidden;

	    	.mask {
	    		transition: transform 0.7s ease;
			    -moz-transition: transform 0.7s ease;
			    -webkit-transition: transform 0.7s ease;
	    		position: absolute;
	    		width: 100%;
	    		height: 100%;
	    		right: 0;
	    		bottom: 0;
	    		background: var(--primary);
	    		opacity: 0.9;
	    		overflow: hidden;
	    		transform: scale(1,0) rotate(-45deg); 
	    		-webkit-transform-origin: bottom center;
    			transform-origin: bottom center;
    			&:before {
    				position: absolute;
    				content:"";
    				bottom: 0;
    				left: 30px;
    				width: 3px;
    				height: 101%;
    				background: linear-gradient( 0deg, rgb(255,255,255) 0%, rgba(255,255,255,0) 100%);
    			}
    			&:after {
    				position: absolute;
    				content:"";
    				bottom: 30px;
    				left: 0;
    				width: 101%;
    				height: 3px;
    				background: linear-gradient( 90deg, rgb(255,255,255) 0%, rgba(255,255,255,0.01) 99%, rgb(255,255,255) 100%);
    			}
	    	}

	    	.apartment-img {
	    		img {
	    			display: block;
	    			width: 100%;
	    			height: 430px;
	    			object-fit: cover;
	    			@media (max-width: 767px) {
	    				height: 360px;
	    			}
	    		}
	    	}

	    	.content-front {
	    		position: absolute;
	    		width: 100%;
	    		height: 100%;
	    		left: 0;
	    		bottom: 0;
	    		z-index: 1;
	    	}

	    	.title {
	    		-webkit-transition: all .2s ease;
    			transition: all .2s ease;
			    transition-delay: 0.2s; 
		    	position: absolute;
                left: 30px;
                bottom: 50px;
                max-width: 220px;

		    	margin: 0 30px 0 0;
    			font-size: 26px;
    			font-weight: 800;
    			line-height: 1.2;
    			color: #fff;
    			z-index: 1;
    			opacity: 0;
    			
    			&:hover {
	            	transition: all .3s ease;
	            	color: var(--heading);
	            }
    		}

	    	.icon {
	    		-webkit-transition: all .2s ease;
    			transition: all .2s ease;
				position: absolute;
				top: 50px;
				right: 30px;
	    		display: inline-flex;
	    		justify-content: center;
	    		align-items: center;
	    		background-color: var(--heading);
	    		width: 50px;
	    		height: 50px;
	    		border-radius: 50%;
	    		z-index: 1;
	    		opacity: 0;
	        	i {
	        		transition: all .3s ease;
	        		display: inline-flex;
	        		color: #fff;
	        	}
	        	&:hover {
	        		transition: all .3s ease;
	        		background-color: #fff;
	        		i {
	        			transition: all .3s ease;
	        			color: var(--primary);
	        		}
	        	}
	    	}
	    }
	}
}