.ova-apartment-slider-2 {
	.slide-apartment-2 {

		.aparment-item-2 {
			&:hover {
				.ova-media {
					.apartment-img {  
                        -webkit-transition: all .5s ease; 
                        transition: all .5s ease;
                        -webkit-transform: scale(1.06);
                        transform: scale(1.06);   
					}
					.apartment-item-content {
						-webkit-transition: all .5s ease; 
						transition: all .5s ease;
						background-color: var(--primary);
						.count {
							-webkit-transition: all .5s ease; 
							transition: all .5s ease; 
							color: var(--heading);
						}
						.icon {
							-webkit-transition: all .5s ease; 
							transition: all .5s ease;
							opacity: 1;
							bottom: 0;
						}
					}
				}
			}

			.ova-media {
				position: relative;
				overflow: hidden;

				.apartment-img {
					-webkit-transition: all .5s ease; 
					transition: all .5s ease; 
					position: relative;

					&:before {
						position: absolute;
						content: "";
						right: 0;
						bottom: 0;
						height: 100%;
						width: 100%;
						opacity: 0.36;
						background-color: var(--heading);
					}
				     
					img {	
						display: block;
						height: 600px; 
						object-fit: cover;
                        @media (max-width: 767px) {
                        	height: 450px;
                        }
					}
				}

				.apartment-item-content { 
					-webkit-transition: all .5s ease; 
					transition: all .5s ease;
					position: absolute;
					bottom: 0;
					right: 0;
					width: 100%;
					max-height: 40%;
					padding: 50px;
					display: flex;
					align-items: center;
					
					@media (max-width: 1400px ) {
						padding: 50px 40px;
					}

					@media (max-width: 420px ) {
						padding: 40px 30px;
					}

					.count {
						-webkit-transition: all .5s ease; 
						transition: all .5s ease; 
						display: inline-block;
						font-size: 60px;
						font-weight: 800;
						opacity: 0.2;
						color: #fff;
						margin-right: 20px;
					}
	
					.category {
						-webkit-transition: all .5s ease; 
						transition: all .5s ease;
						margin: 0 0 6px 0;
						color: #fff;
						font-size: 12px;
						line-height: 1.4;
						font-weight: 600;
						text-transform: uppercase;
						a {
							-webkit-transition: all .3s ease; 
							transition: all .3s ease;
							color: #fff;
							&:hover {
								-webkit-transition: all .3s ease; 
								transition: all .3s ease;
	                        	color: var(--heading);
							}
						}
					}
					.title {
						-webkit-transition: all .5s ease; 
						transition: all .5s ease;
                        margin: 0; 
                        font-size: 24px;
                        font-weight: 600; 
                        line-height: 1.2;   
                        color: #fff; 
                        &:hover {
                        	-webkit-transition: all .3s ease; 
							transition: all .3s ease;
                        	color: var(--heading);
                        }
					}	
					
					.icon {
						-webkit-transition: all .3s ease; 
						transition: all .3s ease;
						position: absolute;
						right: 0;
						bottom: 20px;
						width: 50px;
						height: 50px;
						display: inline-flex;
						justify-content: center;
						align-items: center;
						background-color: #fff; 
						opacity: 0;  
						&:hover {
							-webkit-transition: all .3s ease; 
						    transition: all .3s ease;
							background-color: var(--heading);
							i {
								-webkit-transition: all .3s ease; 
						    	transition: all .3s ease;
								color: #fff;
							}
						}
						i {
							-webkit-transition: all .3s ease; 
						    transition: all .3s ease;
							color: var(--heading);
							display: inline-flex;
							z-index: 1; 
						}
					}
				}
			}
		}

		.owl-dots{
			text-align: center;
			margin-top: 40px;
			@media (max-width: 767px) {
				margin-top: 30px;
			}
			.owl-dot{
				outline: none;
				span{
					width: 16px;
					height: 4px;
					margin: 5px;
					background: #b3b0ba;
					display: block;
					-webkit-backface-visibility: visible;
					transition: opacity .2s ease;
				}
				&.active{
					span{
						background: var(--primary);
					}
				}
			}
		}
	}
}